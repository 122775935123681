/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: Montserrat-Regular;
    src: url(/assets/fonts/Montserrat-Regular.otf);
}
@font-face {
    font-family: SF-UI-Display-Medium;
    src: url(/assets/fonts/SF-UI-Display-Medium.otf);
}
@font-face {
    font-family: SF-UI-Display-Bold;
    src: url(/assets/fonts/SF-UI-Display-Bold.otf);
}
@font-face {
    font-family: Montserrat-Bold;
    src: url(/assets/fonts/Montserrat-Bold.otf);
}
@font-face {
    font-family: Montserrat-SemiBold;
    src: url(/assets/fonts/Montserrat-SemiBold.otf);
}
ion-back-button {
    color: #fff;
}
ion-title {
    text-transform: capitalize;
    letter-spacing: 1px;
}
html.ios, html, ion-app, p, span{
    font-family: Montserrat-Regular!important;
}
// h1, h2, h3, h4, h5, h6, ion-title{
//     font-family: Montserrat-Bold!important;
// }
ion-title.md {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100%;
    text-align: center;
    z-index: -1;
    left: 0;
}
app-search {
    background-color: #131414;
}
app-about{
    background-color: #131414;
}
app-privacy-statement{
    background-color: #131414;
}
app-contactus{
    background-color: #131414;
}
app-edit-profile{
    ion-textarea {
        textarea{
            min-height: 108px;
        }
    }
}

ion-toolbar{
    --background: #000;
    color: #fff;

    ion-menu-button {
        color: #fff;
    }
    span {
        float: right;
        margin-top: 4px;

        img {
            width: 26px;
            margin-right: 12px;
        }
    }
}


h1,h2,h3,h4,h5,h6{
    color: #000
}
ion-alert{
    --color: #fff;
    color: #fff;
    p{
        --color: #fff;
        color: #fff;
    }
}
p.errp{
    font-size: 15px!important;
    margin-top: 12px!important;
    margin-bottom: 13px!important;
    padding: 0px 19px;
    color: #fff!important;

    ion-icon{
        position: relative;
        top: 2px;
        margin-right: 5px;
        color: #fff;
    }
}

ion-alert{
    .alert-wrapper{
        --background: #fff!important;
    }
}

ion-loading{
    .loading-wrapper{
        background: #fff!important;
    }
}

ion-action-sheet{
    .action-sheet-wrapper{
        --background: #fff!important;
    }
    .action-sheet-cancel:after {
        background: #fff!important;
    }
}

ion-picker{
    .picker-wrapper{
        background: #fff!important;
    }
    .picker-above-highlight{
            background: linear-gradient(180deg,var(--background,var(--ion-background-color,#fff)) 20%,rgba(var(--background-rgb,var(--ion-background-color-rgb,255,255,255)),.8))!important;
    }
    .picker-below-highlight{
            background: linear-gradient(180deg,var(--background,var(--ion-background-color,#fff)) 20%,rgba(var(--background-rgb,var(--ion-background-color-rgb,255,255,255)),.8))!important;
    }
}


ion-spinner {
    color: #f04717 !important;
}
app-updated-message{
    ion-textarea{ 
        textarea{
            min-height: 100px;
        }
    }
}